const config = {
  ENVIRONMENT_NAME: 'prod',
  forwoodId: {
    URL: 'https://id.saas.forwoodsafety.com',
        APP_CLIENT_ID: '6gjkc90pu58a7unl8p7t8fn2qm',
    USER_TOKEN_URL: 'https://v4xfxmrgmj.execute-api.us-west-2.amazonaws.com/prod'
  },
  apiGateway: {
    REGION: 'us-west-2',
    URL: 'https://wi0imp78n4.execute-api.us-west-2.amazonaws.com/prod'
  },
  reactApp: {
    VERSION: 'teams-container-1.9.1',
    HOSTNAME: 'https://teams.saas.forwoodsafety.com',
    TEAMS_MICROFRONTEND_URL: 'https://teams-service.saas.forwoodsafety.com',
    TARGETS_MICROFRONTEND_URL: 'https://targets.saas.forwoodsafety.com',
    TEAMS_ACCESS_ROLE: 'TeamsAccess',
    TEAM_TARGETS_ACCESS_ROLE: 'TeamTargetsAccess',
  },
  configurationService: {
    URL: 'https://config.saas.forwoodsafety.com',
    WEBSOCKET: 'wss://k9ttmpq1n8.execute-api.us-west-2.amazonaws.com/prod'
  },
  get forwoodIdAuthUrl() {
    return `${this.forwoodId.URL}/authorize?response_type=token&scope=openid&client_id=${this.forwoodId.APP_CLIENT_ID}&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  }
};

export default config;
